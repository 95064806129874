// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/Poppins.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/fonts/KumbhSans.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/fonts/Assistant.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../public/fonts/OpenSans.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Poppins";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "KumbhSans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Assistant";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "OpenSans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
body {
  font-family: Assistant;
  font-size: 16px;
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: #5061FF;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.scss","webpack://./src/index.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAIA;EACE,sBAAA;EACA,+DAAA;ACHF;ADKA;EACE,wBAAA;EACA,+DAAA;ACHF;ADKA;EACE,wBAAA;EACA,+DAAA;ACHF;ADKA;EACE,uBAAA;EACA,+DAAA;ACHF;AAdA;EACI,sBCEU;EDDV,eCKQ;EDJR,SAAA;AAgBJ;;AAdE;EACE,SAAA;AAiBJ;;AAbE;EACE,qBAAA;EACA,cCbY;AD6BhB","sourcesContent":["@import \"_mixins.scss\";\n@import \"_variables\";\n@import \"_functions\";\n\n@font-face {\n  font-family: 'Poppins';\n  src: url('../../public/fonts/Poppins.ttf') format('truetype'),\n}\n@font-face {\n  font-family: 'KumbhSans';\n  src: url('../../public/fonts/KumbhSans.ttf') format('truetype'),\n}\n@font-face {\n  font-family: 'Assistant';\n  src: url('../../public/fonts/Assistant.ttf') format('truetype'),\n}\n@font-face {\n  font-family: \"OpenSans\";\n  src: url('../../public/fonts/OpenSans.ttf') format('truetype'),;\n}\n//global styles\n\n\n","@import \"styles/index\";\nbody {\n    font-family: $font-family;\n    font-size: $font-size;\n    margin: 0;\n  }\n  p {\n    margin: 0;\n  }\n\n  \n  a {\n    text-decoration: none;\n    color: $primary-color;\n  }\n\n","$primary-color: #5061FF;\n$secondary-color: #6c757d;\n$gray: #6A6A6A;\n$lighter-gray:#F0F0F0;\n$font-family: Assistant;\n$font-family-kumbh: KumbhSans;\n$font-family-poppins: Poppins;\n$font-family-OpenSans:OpenSans;\n$font-size: 16px;\n$text-primary:#374251;\n$light-gray-1: #E0E0E0;\n$light-gray:#929EAE;\n$lighter-gray-2: #7C7C7D;\n$gray-dark:#464646;\n$gray-darker:#46474B;\n$gray-border:#7C7D7D;\n$gray-darker-2: #3A4B5C;\n$white:#fff;\n$black:#000;\n$black-shade:#282828;\n$outline: #EBEBEB;\n$error: #FF5353;\n$white-2: #ECF1F5;\n$white-3: #F2F2F2;\n$block-text : #848484;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
