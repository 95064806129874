import { createSlice } from '@reduxjs/toolkit'
import AppSecureStorage from '../../services/storage/secureStorage'

const storage = new AppSecureStorage()

const initialState = {
  loggedIn: (storage.get(process.env.REACT_APP_ACCESS_TOKEN_KEY) && true) || false,
  isLoading: false,
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    login: (state) => {
      state.loggedIn = true
    },
    logout: (state) => {
      state.loggedIn = false
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
})

export const { login, logout, setLoading } = globalSlice.actions

export default globalSlice.reducer