import React from "react";
import ThankYou from "./thankYou/thankYou";
import { createBrowserRouter } from "react-router-dom";
import { PAGE_ROUTES } from "../utils/constants";
import AddOrEditBusiness from "./addOrUpdateBusiness/AddOrEditBusiness";
import AuthGuard from "../auth/authGuard";
import ImportContacts from "./importContacts/ImportContacts";
import ErrorBoundaryHOC from "../components/errorBoundary/ErrorBoundaryHOC";
import BusinessRecommendations from "./businessRecommendations/BusinessRecommendations";
import UpdateUsers from "./updateUsers/UpdateUsers";
const AdminLayout = React.lazy(() =>
  import("../layouts/AdminLayout/AdminLayout")
);
const Admins = React.lazy(() => import("./admins/Admins"));
const Statistics = React.lazy(() => import("./statistics/Statistics"));
const Error = React.lazy(() => import("./errorPage/Error"));
const Login = React.lazy(() => import("../pages/auth/Login"));
const Business = React.lazy(() => import("./business/Business"));
// const Users = React.lazy(() => import("./Users/Users"));
const Users = React.lazy(() => import("./usersPage/Users"));
const Redirect = React.lazy(() => import("../components/redirect/redirect"))
const AddAdmins = React.lazy(() => import("./addAdmins/AddAdmins"));
const BusinessSignup = React.lazy(() => import("./businessSignup/BusinessSignupPage"));
const routes = createBrowserRouter([
  {
    path: "/",
    element: <Redirect /> ,
  },
  {
    path: "/",
    element: <AuthGuard><AdminLayout /></AuthGuard>,
    children: [
      {
        path: PAGE_ROUTES.HOME,
        element: <ErrorBoundaryHOC>
          <Statistics />
        </ErrorBoundaryHOC>
      },
      {
        path: PAGE_ROUTES.STATICS,
        element: <ErrorBoundaryHOC>
          <Statistics />
        </ErrorBoundaryHOC>
      },
      {
        path: PAGE_ROUTES.BUSINESS,
        element: <ErrorBoundaryHOC>
          <Business />
        </ErrorBoundaryHOC>
      },
      {
        path: PAGE_ROUTES.USERS,
        element: <ErrorBoundaryHOC>
          <Users />
        </ErrorBoundaryHOC>
      },
      {
        path: PAGE_ROUTES.USER_EDIT,
        element: <ErrorBoundaryHOC>
          <UpdateUsers />
        </ErrorBoundaryHOC>
      },
      {
        path: PAGE_ROUTES.ADMINS,
        element: <ErrorBoundaryHOC>
          <Admins />
        </ErrorBoundaryHOC>
      },
      {
        path: PAGE_ROUTES.ADD_ADMINS,
        element: <ErrorBoundaryHOC>
          <AddAdmins />
        </ErrorBoundaryHOC>
      },
      {
        path: PAGE_ROUTES.BUSINESS_ADD,
        element: <ErrorBoundaryHOC>
          <AddOrEditBusiness />
        </ErrorBoundaryHOC>
      },
      {
        path: PAGE_ROUTES.BUSINESS_RECOMMENDATIONS,
        element: <ErrorBoundaryHOC>
          <BusinessRecommendations /> 
        </ErrorBoundaryHOC>
      },
    ],
  },
  {
    path:PAGE_ROUTES.LOGIN,
    element:<Login />
  },
  {
    path:PAGE_ROUTES.BUSINESS_SIGNUP,
    element:<ErrorBoundaryHOC>
      <BusinessSignup/>
    </ErrorBoundaryHOC>
  },
  {
    path:PAGE_ROUTES.THANK_YOU,
    element:<ThankYou />
  },
  {
    path: PAGE_ROUTES.IMPORT_CONTACTS,
    element: <ErrorBoundaryHOC>
      <ImportContacts />
    </ErrorBoundaryHOC>
  },
  { path: "*", element: <Error /> },
]);

export default routes;
