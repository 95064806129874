// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/fonts/Poppins.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/fonts/KumbhSans.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../public/fonts/Assistant.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../public/fonts/OpenSans.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Poppins";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "KumbhSans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Assistant";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "OpenSans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
.Button_button__Rzx8I {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.scss","webpack://./src/components/buttons/Button.module.scss"],"names":[],"mappings":"AAIA;EACE,sBAAA;EACA,+DAAA;ACHF;ADKA;EACE,wBAAA;EACA,+DAAA;ACHF;ADKA;EACE,wBAAA;EACA,+DAAA;ACHF;ADKA;EACE,uBAAA;EACA,+DAAA;ACHF;AAbA;EAEE,qBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;AAcF","sourcesContent":["@import \"_mixins.scss\";\n@import \"_variables\";\n@import \"_functions\";\n\n@font-face {\n  font-family: 'Poppins';\n  src: url('../../public/fonts/Poppins.ttf') format('truetype'),\n}\n@font-face {\n  font-family: 'KumbhSans';\n  src: url('../../public/fonts/KumbhSans.ttf') format('truetype'),\n}\n@font-face {\n  font-family: 'Assistant';\n  src: url('../../public/fonts/Assistant.ttf') format('truetype'),\n}\n@font-face {\n  font-family: \"OpenSans\";\n  src: url('../../public/fonts/OpenSans.ttf') format('truetype'),;\n}\n//global styles\n\n\n","@import \"src/styles/index\";\n\n.button {\n  // width: 200px;\n  display: inline-block;\n  padding: 10px 20px;\n  border: none;\n  border-radius: 5px;\n  // transition: background-color 0.2s ease-in-out;\n  //     &:hover {\n  //     background-color: lighten($primary-color, 30%);\n  //   }\n  \n  //   &.secondary {\n  //     background-color: $secondary-color;\n  \n  //     &:hover {\n  //       background-color: lighten($secondary-color, 30%);\n  //     }\n  //   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__Rzx8I`
};
export default ___CSS_LOADER_EXPORT___;
