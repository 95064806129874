import React from "react";
import { ErrorBoundary } from "react-error-boundary";

const ErrorBoundaryHOC = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={
        <div style={{ textAlign: "center", marginTop: "5rem" }}>
          <h2>Something went wrong</h2>
        </div>
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryHOC;
