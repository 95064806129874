import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import UserService from '../../services/api/userService';
import { setLoading } from './global';

const userService = new UserService();

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (body, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const userResponse = await userService.getAllUsers(body);
    dispatch(setLoading(false));
    // toast.success("Users list fetched Successfully");
    return { data: userResponse.data, total: userResponse.total };
  } catch (error) {
    toast.error("loading user list failed");
    dispatch(setLoading(false));
  }
});

const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    total: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload?.data;
      state.total = action.payload?.total;
    });
  },
})

export default userSlice.reducer