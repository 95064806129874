const PAGE_ROUTES = {
  HOME : '/',
  STATICS: 'statistics',
  USERS: 'users',
  ADMINS: 'admins',
  ADD_ADMINS: 'admins/add-admins',
  BUSINESS:'business',
  BUSINESS_ADD: 'business/add-edit',
  USER_EDIT:'users/user/edit',
  LOGIN:'login',
  BUSINESS_SIGNUP:'business-signup/:phoneNumber',
  THANK_YOU:'thank-you',
  IMPORT_CONTACTS: 'm-c/:phoneNumber/:botId',
  BUSINESS_RECOMMENDATIONS: "/business/:businessId/recommendations"
}

const API_ROUTES = {
  BASE_URL : process.env.REACT_APP_BACKEND_API_URL,
  AUTHENTICATION: {
    LOGIN_AUTH : 'auth/login',
    LOGOUT_AUTH : 'auth/logout',
  },
  ADMIN: {
    ADMINS: "admins",
  },
  BUSINESS:{
    BUSINESS:"business",
    LOCATION:"map/find",
    STATS: "stats",
    SUGGEST:"map/suggest",
    BUSINESS_REGISTER:"business/register",
    RECOMMENDATIONS:"recommendations",
  },
  USERS: {
    USER: "user",
    STATS: "stats",
    INFO: 'user/info',
    CONTACTS: 'user/contacts',
    RECOMMENDATIONS: 'recommendations',
  },
  CATEGORY: {
    CATEGORY: "category",
    SUGGEST: "category/suggest",
  },
  ANALYTICS: {
    CONVERSATION: {
      TOP_CATEGORIES: "analytics/top_categories",
      TOP_LOCATIONS: "analytics/top_locations",
      AVERAGE_SEARCH: "analytics/average_search",
    }
  }
}

const CONFIG = {
  TIMEOUT : 30000
}

const USERS = [
  {
    phone: 543739533,
    subscription: "Extended",
    name: "tests",
  },
  {
    phone: 543730533,
    subscription: "Extended",
    name: "tests2",
  },
  {
    phone: 543335533,
    subscription: "Extended",
    name: "tests3",
  },
  {
    phone: 543765533,
    subscription: "Extended",
    name: "tests4",
  },
  {
    phone: 543735533,
    subscription: "Extended",
    name: "tests5",
  },
];

const BUSINESSES = [
  {
    id: 1,
    dateOfAddition: "2023-10-05",
    businessName: "Business A",
    ownerName: "Owner A",
    category: "Restaurant",
    location: "123 Main",
    phoneNumber: "555-111-1111",
    numberOfRecommendations: 15,
    registered: true
  },
  {
        id: 2,
    businessName: "Business B",
    ownerName: "Owner B",
    category: "Retail",
    location: "City B",
    phoneNumber: "555-222-2222",
    numberOfRecommendations: 8,
    dateOfAddition: "2023-10-05",
    registered: true
  },
  {
    id: 3,
    businessName: "Business C",
    ownerName: "Owner C",
    category: "Service",
    location: "Oak Street",
    phoneNumber: "535-333-3333",
    numberOfRecommendations: 20,
    dateOfAddition: "2023-10-05",
    registered: true
  },
  {
    id: 4,
    businessName: "Business D",
    ownerName: "Owner C",
    category: "Service",
    location: "Oak Street",
    phoneNumber: "555-333-3383",
    numberOfRecommendations: 20,
    dateOfAddition: "2023-10-05",
    registered: false
  },
  {
    id: 5,
    businessName: "Business E",
    ownerName: "Owner C",
    category: "Service",
    location: "Oak Street",
    phoneNumber: "555-353-3333",
    numberOfRecommendations: 20,
    dateOfAddition: "2023-10-05",
    registered: true
  },
];

const BUSINESS_FILTER_OPTIONS = ["All", "Registered Business", "Unregistered Business"];

const RESULT_PER_PAGE_OPTIONS = [10, 50, "all"];

const THROTTLE_DURATION =  500

const DIGIT_ONLY_REGEX = "/^\d+$/"

export { PAGE_ROUTES, CONFIG, API_ROUTES, USERS, BUSINESSES, BUSINESS_FILTER_OPTIONS, RESULT_PER_PAGE_OPTIONS, THROTTLE_DURATION, DIGIT_ONLY_REGEX }