import React, {useEffect, useRef, useState} from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import styles from "./AddOrEditBusiness.module.scss";
import BusinessService from "../../services/api/businessService";
import CategoryService from "../../services/api/categoryService";
import { toast } from "react-toastify";
import { fetchBusinesses } from "../../redux/slices/business";
import { formatDate, throttle } from "../../utils/helpers";
import { THROTTLE_DURATION } from "../../utils/constants";
import { close } from "../../assets";

const business = new BusinessService();
const categoryService = new CategoryService();
const AddOrEditBusiness = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams();
  const [businessData, setBusinessData] = useState({})
  const businessId = searchParams.get("businessId")
  const [searchLocationList, setSearchLocationList] = useState([]);
  const [isListVisible, setListVisible] = useState(false);
  const [locationValue, setLocationValue] = useState("");
  const [searching, setSearching] = useState(false)
  const [isSearchingCategory, setIsSearchingCategory] = useState(false)
  const [searchCategoryList, setSearchCategoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [categoryError, setCategoryError] = useState(false)
  const [locationError, setLocationError] = useState(false)
  const [isCustomLocation, setIsCustomLocation] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const searchLocation = async (e) => {
    if(!e.target.value)
      return;
    setListVisible(true)
    try {
      setSearching(true)
      const response = await business.suggestCities(e.target.value);
      if(response) {
        const cities = response.predictions?.map((item) => item.description)
        setSearchLocationList(cities)
      }
    } catch (error) {
      console.error('err while searching', error)
    } finally {
      setSearching(false)
    }
  }
  
  const searchCategories = async (e) => {
    if (!e.target.value) return;
    try {
      setIsSearchingCategory(true);
      const response = await categoryService.suggestCities(e.target.value);
      if (response) {
        const suggestedCategories = response.map((item) => item.name);
        setSearchCategoryList(suggestedCategories);
      }
    } catch (error) {
      console.error('err while searching', error);
    } finally {
      setIsSearchingCategory(false);
    }
  };
  
  const throttledSearchLocation = useRef(throttle(searchLocation, THROTTLE_DURATION)).current;
  const throttledSearchCategory = useRef(throttle(searchCategories, THROTTLE_DURATION)).current;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      businessName: businessData?.businessName,
      ownerName:businessData?.ownerName,
      categories:businessData?.categories || [],
      location:businessData?.location || [],
      phoneNumber:businessData?.phoneNumber || "+972"
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchBusinessesById(businessId)
  }, [businessId])


  useEffect(() => {
    if (Object.keys(businessData).length > 0) {
      let newPhone = businessData.phoneNumber;
      if(businessData.phoneNumber.startsWith("972")) {
        newPhone = "+" + businessData.phoneNumber
      } 
      reset({
        businessName: businessData.businessName,
        ownerName: businessData.ownerName,
        categories: businessData.categories,
        location: businessData.location,
        phoneNumber: newPhone
      });      
    }
  }, [businessData, reset]);
  
  const fetchAllBusinesses = async () => {
    const body = {
      page: 1,
      limit: 0,
      status: ""
    }
    dispatch(fetchBusinesses(body, dispatch))
  };
  
  const formdata = watch()
  const onSubmit = async(data) => {
    if(data.phoneNumber === "+972") {
      setPhoneError(true)
      return;
    }

    // let newPhone = data.phoneNumber;
    // if(data.phoneNumber.startsWith("+972")) {
    //   newPhone = newPhone.slice(1)
    // } else if(!data.phoneNumber.includes("+972")) {
    //   // newPhone
    // }

    const updatedData = {
      ...data, 
      phoneNumber: data.phoneNumber.slice(1) 
    }

    try {
        if(!!businessId) {
          await business.editBusiness(businessId, updatedData);
          // toast.success("Business Edited Successfully");
        } else {
          await business.addBusiness(updatedData);
          fetchAllBusinesses()
          // toast.success("Business Added Successfully");
        }
       navigate("/business")
    } catch (error) {
      console.log('err while logging in', error)
      if (error.response && error.response.status === 401) {
        console.log('Unauthorized (401): Invalid credentials');
        toast.error("wrong credentials");
      } else {
        toast.error("An error occurred:");
      }
    }
  };

  const fetchBusinessesById = async (businessId) => {
    try {
      const response = await business.getBusinessById(businessId);
      if (response) {
        setBusinessData(response);
        // setLocationValue(response.location)
      } else {
        console.log("Invalid response from server:", response);
      }
    } catch (error) {
        console.error("A server side error occurred:", error);
    }
  };

  const handleDeleteCategory = (index) => {
  
    const updatedCategories= [...formdata.categories]
    updatedCategories.splice(index, 1)
    setValue("categories", updatedCategories)
  }

  const handleDeleteLocation = (index) => {

    const updatedLocation = [...formdata.location]
    updatedLocation.splice(index, 1)
    setValue("location", updatedLocation)
  }

 
  const handleAddCategory = (item) => {
    if(formdata.categories.length < 5) {
      const updatedCategories = [...formdata.categories, item]
      setValue("categories", updatedCategories)
    } else {
      setCategoryError(true)
    }
  }

  const handleAddLocation = (item) => {
    if (!formdata.location.includes(item)) {
      if (formdata.location.length < 5) {
        const updatedLocation = [...formdata.location, item];
        setValue("location", updatedLocation);
      } else {
        setLocationError(true);
      }
    }
  }

  console.log('err', errors)
  console.log('phoneNu', formdata.phoneNumber)
  console.log('len', formdata.phoneNumber.length)
  console.log('err', phoneError)
  return (
    <form className={styles.newBusinessForm} onSubmit={handleSubmit(onSubmit)}>
      <h2>{!!businessId ? "Edit Business" : "New Business"}</h2>
      <div className={styles.formGroup}>
        <label htmlFor="businessName">Business Name</label>
        <Controller
          name="businessName"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              placeholder="Business Name"
              dir="rtl" // Adding RTL direction
              style={{ textAlign: "right" }} // Optionally align text to the right
            />
          )}
        />
        {errors.businessName && (
          <p className={styles.error}>Business Name is required</p>
        )}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="ownerName">Owner Name</label>
        <Controller
          name="ownerName"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <input {...field} type="text" placeholder="Owner Name" dir="rtl" 
              style={{ textAlign: "right" }} 
               />
            
          )}
        />
        {errors.ownerName && (
          <p className={styles.error}>Owner Name is required</p>
        )}
      </div>
      <div className={styles.formGroup}>
        <div className={styles.labelLoaderWrapper}>
          <label htmlFor="categories">Categories</label> {isSearchingCategory && <div className={styles.spinner}></div>}
        </div>
        <Controller
          name="categories"
          control={control}
          defaultValue=""
          rules={{ required: formdata.categories.length === 0 }}
          render={({ field }) => (
            <input {...field} type="text" value={categoryValue}
              onChange={
                (e) => { 
                  setCategoryValue(e.target.value)
                  throttledSearchCategory(e) 
                  setIsCategorySelected(false)
                  setCategoryError(false)
                }
              } 
              placeholder="Categories"
              className={styles.focusInput}
            />
          )}
        />
        {categoryError && (
          <p className={styles.error}>You can select upto 5 categories</p>
        )}
       <div className={styles.selectedCategories}>
        {formdata.categories.map((item, index) =>
          <div 
            key={index} 
            className={styles.selectedCategory}
          >
            {item} 
            <img 
              src={close} 
              onClick={() => {
                handleDeleteCategory(index);
              }} 
              alt=""
            />
          </div>
        )}
        </div>
        {!isCategorySelected && searchCategoryList?.length > 0 &&
        <div className={styles.listBox}>
          <ul>
            {searchCategoryList.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  handleAddCategory(item)
                  setCategoryValue(item);
                  setIsCategorySelected(true);
                  setCategoryValue("")
                }}
                className={styles.listItem}
              >
                {item}
              </li>
            ))}
            </ul>
        </div>}
        {errors.categories && (
          <p className={styles.error}>Please select Categories from suggestions!</p>
        )}
      </div>

      <div className={styles.formGroup}>
        <div className={styles.labelLoaderWrapper}>
          <label htmlFor="location">Location</label> {searching && <div className={styles.spinner}></div>}
        </div>
        <Controller
          name="location"
          control={control}
          defaultValue=""
          rules={{ required: formdata.location.length === 0 }}  
           render={({ field }) => (
              <input {...field} type="text" value={locationValue} onChange={
                (e) => { 
                  setLocationValue(e.target.value)
                  throttledSearchLocation(e)
                  setIsCustomLocation(true)
                }
              } placeholder="Location"
                className={styles.focusInput}
              />
            )}
        />
        {locationError && (
          <p className={styles.error}>You can select upto 5 location</p>
        )}
       {isListVisible && searchLocationList?.length > 0 &&  <div className={styles.listBox}>
       <ul>
        {searchLocationList.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                handleAddLocation(item)
                // setValue("location", item);
                setLocationValue(item);
                setListVisible(false)
                setIsCustomLocation(false)
                setLocationValue("");
              }}
              className={styles.listItem}
            >
              {item}
            </li>
          ))}
        </ul>
        </div>}
        {errors.location && (
          <p className={styles.error}>יש להקליד ולבחור מיקום מהרשימה המוצעת</p>
        )}
        <div className={styles.selectedCategories}>
          {formdata.location.map((item, index) =>
            <div
              key={index}
              className={styles.selectedCategory}
            >
              {item}
              <img
                src={close}
                onClick={() => {
                  handleDeleteLocation(index);
                }}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="phoneNumber">Phone Number</label>
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          rules={{}}
          render={({ field }) => (    
            <input
              {...field}
              type="text"
              placeholder="Phone Number"
              className={styles.focusInput}
              value={formdata?.phoneNumber?.startsWith("+972") ? formdata?.phoneNumber : `+972${formdata.phoneNumber ? formdata.phoneNumber : ""}`}
              onChange={(e) => {
                setPhoneError(false)
                const userInput = e.target.value;
            
                if (!userInput.startsWith("+972")) {
                  setValue('phoneNumber', "+972");
                  return;
                }
            
                if (userInput.startsWith("+9720")) {
                  setValue('phoneNumber', "+972");
                  return;
                }
            
                const rawPhoneNumber = userInput.replace(/\D/g, '').replace(/^0+/, '');
                setValue('phoneNumber', `+${rawPhoneNumber}`);
              }}
            />            
          )}
        />
        {(phoneError) && (
          <p className={styles.error}>לא ניתן להזין מספר טלפון שאינו ישראלי</p>
        )}
      </div>

      <div className={styles.formActions}>
        <div className={styles.save}>
          <Button>{!!businessId ? "Edit Business" : "New Business"}</Button>
        </div>
        <div
          className={styles.cancel}
          onClick={() => {
            reset();
            navigate("/business/");
          }}
        >
          <Button>Cancel</Button>
        </div>
      </div>
    </form>
  );
};

export default AddOrEditBusiness;