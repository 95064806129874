import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { pointerArrowLeftIcon } from "../../assets";
import Dropdown from "../../components/customDropDown/DropDown";
import {
  fetchBusinessesDetails,
  fetchBusinessesRecommendations,
} from "../../redux/slices/business";
import { RESULT_PER_PAGE_OPTIONS } from "../../utils/constants";
import styles from "./BusinessRecommendations.module.scss";

const BusinessRecommendations = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const businessId = params.businessId;
  const { selectedBusinessDetails, recommendations } = useSelector(
    (state) => state.businessReducer
  );
  const { users, total } = recommendations;
  const [resultsPerPage, setResultsPerPage] = useState("all");
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchBusinessesDetails(businessId));
    dispatch(
      fetchBusinessesRecommendations({
        businessId: businessId,
        page: 1,
        limit: 0,
      })
    );
  }, [dispatch, businessId]);

  useEffect(() => {
    dispatch(fetchBusinessesRecommendations({ businessId, page, limit: resultsPerPage === "all" ? 0 : parseInt(resultsPerPage) }));
  }, [dispatch, businessId, resultsPerPage, page]);

  const handleResultPerPage = (value) => {
    setResultsPerPage(value);
  };

  const getTotalPages = () => {
    let totalPages = 1;
    if (resultsPerPage === "all") {
      return 1;
    } else if (users?.length > 0) {
      totalPages = Math.ceil(total / resultsPerPage);
    }
    return totalPages;
  };

  const handlePageChange = (operation) => {
    if (operation === "next" && page < total) {
      setPage(page + 1);
    } else if (operation === "prev" && page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {selectedBusinessDetails?.businessName ||
          selectedBusinessDetails?.ownerName}{" "}
        - recommendations
      </h2>
      {(total !== 0 && users !== []) ? (<div><div className={styles.resultsPerPage}>
        <span>Results per page</span>
        <Dropdown
          options={RESULT_PER_PAGE_OPTIONS}
          defaultValue={resultsPerPage || RESULT_PER_PAGE_OPTIONS[0]}
          onSelect={handleResultPerPage}
        />
      </div>
      <div className={styles.userTable}>
        <div className={styles.tableHeader}>
          <div className={styles.headerItem}>The name of the recommender</div>
          <div className={styles.headerItem}>Phone Number</div>
          <div className={styles.headerItem}></div>
        </div>
        <div className={styles.tableBody}>
          <div className={styles.cells}>
            {!users && <h1>Loading...</h1>}
            {users &&
              users.length > 0 &&
              users?.map((item, index) => (
                <div key={index}
                  className={
                    item.blocked
                      ? `${styles.blockedText} ${styles.items}`
                      : styles.items
                  }
                >
                  <div className={styles.item}>{item.name}</div>
                  <div className={`${styles.item} ${styles.phoneNum}`}>+{item.phoneNumber}</div>
                  <div className={styles.item}></div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.tableFooter}>
          <div
            className={styles.displaying}
          >{`Displaying ${page} of ${getTotalPages()}`}</div>
          <div className={styles.pages}>
            <div
              className={styles.back}
              disabled={page === 1}
              onClick={() => {
                handlePageChange("prev");
              }}
            >
              <img src={pointerArrowLeftIcon} alt="" />
            </div>
            <div className={styles.number}>{page}</div>
            <div
              className={styles.forward}
              onClick={() => {
                if (getTotalPages() !== page) {
                  handlePageChange("next");
                }
              }}
            >
              <img src={pointerArrowLeftIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      </div>
      ): "אין המלצות"}
    </div>
  );
};

export default BusinessRecommendations;
