import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import BusinessService from '../../services/api/businessService';
import { setLoading } from './global';

const businessService = new BusinessService();

export const fetchBusinesses = createAsyncThunk('business/fetchBusinesses', async (body, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const businessResponse = await businessService.getAllBusinesses(body);
    dispatch(setLoading(false));
    // toast.success("Business list fetched Successfully");
    return { data: businessResponse.data, total: businessResponse.total };
  } catch (error) {
    toast.error("loading business list failed");
    dispatch(setLoading(false));
  }
});

export const fetchBusinessesRecommendations = createAsyncThunk('business/fetchBusinessesRecommendations', async (body, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const businessResponse = await businessService.getBusinessRecommendations(body);
    dispatch(setLoading(false));
    // toast.success("Business recommendations list fetched Successfully");
    return { users: businessResponse};
  } catch (error) {
    toast.error("Business recommendations list failed");
    dispatch(setLoading(false));
  }
});

export const fetchBusinessesDetails = createAsyncThunk('business/fetchBusinessesDetails', async (id, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const businessResponse = await businessService.getBusinessById(id);
    dispatch(setLoading(false));
    // toast.success("Business recommendations list fetched Successfully");
    return { data: businessResponse};
  } catch (error) {
    toast.error("Business recommendations list failed");
    dispatch(setLoading(false));
  }
});

const businessSlice = createSlice({
  name: 'business',
  initialState: {
    businesses: [],
    recommendations: {},
    selectedBusinessDetails: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBusinesses.fulfilled, (state, action) => {
      state.businesses = action.payload?.data;
      state.total = action.payload?.total;
    });
    builder.addCase(fetchBusinessesRecommendations.fulfilled, (state, action) => {
      state.recommendations = {
        users: action.payload?.users.users,
        total: action.payload?.users.total
      }
    });
    builder.addCase(fetchBusinessesDetails.fulfilled, (state, action) => {
      state.selectedBusinessDetails = action.payload?.data;
    });
  },
})

export default businessSlice.reducer