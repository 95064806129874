import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux'

const AuthGuard = ({ children }) => {
    const { loggedIn } = useSelector((state) => state.globalReducer);
  if (loggedIn) {
    return <>{children}</>;
  } else {
    return <Navigate to={"/login"} />;
  }
};

export default AuthGuard;