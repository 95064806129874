import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import AdminService from '../../services/api/adminService';
import { setLoading } from './global';

const adminService = new AdminService();

export const fetchAdmins = createAsyncThunk('admins/fetchUsers', async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const adminsResponse = await adminService.getAllAdmins();
    dispatch(setLoading(false));
    // toast.success("Admins list fetched Successfully");
    return adminsResponse;
  } catch (error) {
    console.log('err', error)
    toast.error("loading admins list failed");
    dispatch(setLoading(false));
  }
});

const adminsSlice = createSlice({
  name: 'admins',
  initialState: {
    admins: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdmins.fulfilled, (state, action) => {
      state.admins = action.payload;
    });
  },
})

export default adminsSlice.reducer