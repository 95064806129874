
import { API_ROUTES } from '../../utils/constants';
import { AxiosBase } from './index';

export default class AnalyticsService extends AxiosBase {
  getTopCategories = async () => {
    try {
      const response = await this.requests.get(`${API_ROUTES.ANALYTICS.CONVERSATION.TOP_CATEGORIES}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  getAverageSearches = async () => {
    try {
      const response = await this.requests.get(`${API_ROUTES.ANALYTICS.CONVERSATION.AVERAGE_SEARCH}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  getTopLocations = async () => {
    try {
      const response = await this.requests.get(`${API_ROUTES.ANALYTICS.CONVERSATION.TOP_LOCATIONS}`);
      return response;
    } catch (err) {
      throw err
    }
  }
}