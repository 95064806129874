import React, { useEffect, useRef, useState } from "react";
import styles from "./DropDown.module.scss";
import { arrowDownIcon } from "../../assets/index";

const Dropdown = ({ options, onSelect, defaultValue }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <span className={styles.label}>{selectedOption || defaultValue}</span>
        <img
          src={arrowDownIcon}
          className={isOpen ? styles.rotateArrow : ""}
          alt=""
        />
      </button>
      {isOpen && (
        <ul>
          {options.map((option) => (
            <li key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
