import React from 'react';
import styles from './thankYou.module.scss'

const ThankYou = () => {
  return (
    <div className={styles.thankyou}>
      <p>תודה</p>
      <p dir='rtl'>הפרטים שלך נשמרו בהצלחה!</p>
    </div>
  )
}

export default ThankYou