
import { API_ROUTES } from '../../utils/constants';
import { AxiosBase } from './index';

export default class BusinessService extends AxiosBase {
  getAllBusinesses = async (body) => {
    const { page, limit, status } = body
    try {
      const response = await this.requests.get(`${API_ROUTES.BUSINESS.BUSINESS}?page=${page}&limit=${limit}&status=${status}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  addBusiness = async (body) => {
    try {
      const response = await this.requests.post(`${API_ROUTES.BUSINESS.BUSINESS}`, body);
      return response;
    } catch (err) {
      throw err
    }
  }

  fetchLocation = async (query) => {
    try {
      const response = await this.requests.get(`${API_ROUTES.BUSINESS.LOCATION}?query=${query}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  getBusinessById = async (id) => {
    try {
      const response = await this.requests.get(`${API_ROUTES.BUSINESS.BUSINESS}/${id}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  editBusiness = async (id, body) => {
    try {
      const response = await this.requests.put(`${API_ROUTES.BUSINESS.BUSINESS}/${id}`, body);
      return response;
    } catch (err) {
      throw err
    }
  }

  blockBusiness = async (id) => {
    try {
      const response = await this.requests.put(`${API_ROUTES.BUSINESS.BUSINESS}/${id}/block`);
      return response;
    } catch (err) {
      throw err
    }
  }

  deleteBusiness = async (id) => {
    try {
      const response = await this.requests.delete(`${API_ROUTES.BUSINESS.BUSINESS}/${id}`);
      return response;
    } catch (err) {
      throw err
    }

  }

  getBusinessStats = async (body) => {
    const { type } = body
    try {
      const response = await this.requests.get(`${API_ROUTES.BUSINESS.BUSINESS}/${API_ROUTES.BUSINESS.STATS}?type=${type}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  suggestCities = async (query) => {
    try {
      const response = await this.requests.get(`${API_ROUTES.BUSINESS.LOCATION}/${query}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  getBusinessByPhone = async (phone,code) => {
    try {
      const response = await this.requests.get(`${API_ROUTES.BUSINESS.BUSINESS_REGISTER}/${phone}?c=${code}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  registerBusiness = async (id,code,body) => {
    try {
      const response = await this.requests.put(`${API_ROUTES.BUSINESS.BUSINESS_REGISTER}/${id}?c=${code}`,body);
      return response;
    } catch (err) {
      throw err
    }
  }

  getBusinessRecommendations = async (body) => {
    const { businessId, page, limit } = body
    try {
      const response = await this.requests.get(`${API_ROUTES.BUSINESS.BUSINESS}/${businessId}/${API_ROUTES.BUSINESS.RECOMMENDATIONS}?page=${page}&limit=${limit}`);
      return response;
    } catch (err) {
      throw err
    }
  }

}