
import { API_ROUTES } from '../../utils/constants';
import { AxiosBase } from './index';

export default class CategoryService extends AxiosBase {
  getAllBusinesses = async () => {
    try {
      const response = await this.requests.get(`${API_ROUTES.BUSINESS.BUSINESS}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  suggestCities = async (query) => {
    try {
      const response = await this.requests.get(`${API_ROUTES.CATEGORY.SUGGEST}/${encodeURIComponent(query)}`);
      return response;
    } catch (err) {
      throw err
    }
  }
}