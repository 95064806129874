import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import styles from "./UpdateUsers.module.scss";
import { toast } from "react-toastify";
import UserService from "../../services/api/userService";
import { fetchUsers } from "../../redux/slices/users";

const userService = new UserService();
const UpdateUsers = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId")
  const name = searchParams.get("name")

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      name: name,
    },
  });


  const fetchAllUsers = async (page, resultsPerPage) => {
    const body = {
      page: page,
      limit: resultsPerPage === "all" ? 0 : resultsPerPage,
    };
    dispatch(fetchUsers(body, dispatch));
  };

  const onSubmit = async (data) => {

    try {
      if (!!userId) {
        await userService.editUser(userId, data);
        // toast.success("User Updated Successfully");
        fetchAllUsers(1,"all");
        navigate("/users")
      }

    } catch (error) {
      console.log('err while logging in', error)
      if (error.response && error.response.status === 401) {
        console.log('Unauthorized (401): Invalid credentials');
        toast.error("wrong credentials");
      } else {
        toast.error("An error occurred:");
      }
    }
  };


  return (
    <form className={styles.newBusinessForm} onSubmit={handleSubmit(onSubmit)}>
      <h2>Edit User</h2>
      <div className={styles.formGroup}>
        <label htmlFor="name">User Name</label>
        <Controller
          name="name"
          control={control}
          defaultValue={name}
          rules={{ required: true }}
          render={({ field }) => (
            <input {...field} type="text" placeholder="User Name" />
          )}
        />
        {errors.name && (
          <p className={styles.error}>User Name is required</p>
        )}
      </div>
      <div className={styles.formActions}>
        <div className={styles.save}>
          <Button>Update User</Button>
        </div>
        <div
          className={styles.cancel}
          onClick={() => {
            reset();
            navigate("/users/");
          }}
        >
          <Button>Cancel</Button>
        </div>
      </div>
    </form>
  );
};

export default UpdateUsers;