import React from "react";
import { RouterProvider } from "react-router-dom";
import routes from "../src/pages/routes";
import { store } from './redux/store';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ErrorBoundaryHOC from "./components/errorBoundary/ErrorBoundaryHOC";

const App = () => {
  return (
    <ErrorBoundaryHOC>
      <Provider store={store}>
        <React.Suspense fallback={"...loading"}>
          <RouterProvider router={routes} />
        </React.Suspense>
        <ToastContainer
          hideProgressBar={true}
          autoClose={2000}
        />
      </Provider>
    </ErrorBoundaryHOC>
  );
};

export default App;
