import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './slices/global'
import statsReducer from './slices/stats'
import businessReducer from './slices/business'
import usersReducer from './slices/users'
import adminsReducer from './slices/admin'

export const store = configureStore({
  reducer: {
    globalReducer,
    statsReducer,
    businessReducer,
    usersReducer,
    adminsReducer
  },
})