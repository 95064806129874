export function throttle(fn, delay) {
  let run = false
  return function (...args) {
    if (!run) {
      fn(...args)
      run = true
      setTimeout( () => run = false, delay)
    }
  }
}

export const formatDate = (date) => {
  const formattedDate = new Date(date).toLocaleDateString('he-IL');
  return formattedDate;
};

export const isContactsSelectionSupported = () => {
  return (
    navigator.contacts &&
    typeof navigator.contacts.select === 'function'
  );
};

export const isDigitOnly = (input) => {
  return/^\d+$/.test(input);
}
