
import { API_ROUTES } from '../../utils/constants';
import { AxiosBase } from './index';

export default class AdminService extends AxiosBase {
  addAdmin = async (body) => {
    try {
      const response = await this.requests.post(`${API_ROUTES.ADMIN.ADMINS}`, body);
      return response;
    } catch (err) {
      throw err
    }
  }
  getAllAdmins = async () => {
    try {
      const response = await this.requests.get(`${API_ROUTES.ADMIN.ADMINS}`);
      return response;
    } catch (err) {
      throw err
    }
  }
  deleteAdmin = async (id) => {
    try {
      const response = await this.requests.delete(`${API_ROUTES.ADMIN.ADMINS}/${id}`);
      return response;
    } catch (err) {
      throw err
    }
  }
}