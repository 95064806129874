import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
import AnalyticsService from '../../services/api/AnalyticsService';
import BusinessService from '../../services/api/businessService';
import UserService from '../../services/api/userService';
import { setLoading } from './global';

const businessService = new BusinessService();
const userService = new UserService();
const analyticsService = new AnalyticsService();

export const fetchBusinessStats = createAsyncThunk('stats/fetchBusinessStats', async (type, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const businessStatsResponse = await businessService.getBusinessStats({ type });
    dispatch(setLoading(false));
    // toast.success("Business stats fetched Successfully");
    return businessStatsResponse.data;
  } catch (error) {
    toast.error("loading business stats failed");
    dispatch(setLoading(false));
    return {}
  }
});

export const fetchUserStats = createAsyncThunk('stats/fetchUserStats', async (type, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const userStatsResponse = await userService.getUserStats({ type });
    dispatch(setLoading(false));
    // toast.success("User stats fetched Successfully");
    return userStatsResponse.data;
  } catch (error) {
    toast.error("loading business stats failed");
    dispatch(setLoading(false));
    return {}
  }
});

export const fetchConversationAnalytics = createAsyncThunk('stats/fetchConversationAnalytics', async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const [averageSearches, topCategories, topLocations] = await Promise.all([
      analyticsService.getAverageSearches(),
      analyticsService.getTopCategories(),
      analyticsService.getTopLocations(),
    ]);
    dispatch(setLoading(false));
    // toast.success("Conversation stats fetched Successfully");
    const conversationAnalytics = {
      averageSearches,
      topCategories,
      topLocations
    }
    return conversationAnalytics
  } catch (error) {
    toast.error("loading business stats failed");
    dispatch(setLoading(false));
    return {}
  }
});

const statsSlice = createSlice({
  name: 'stats',
  initialState: {
    userStats: [],
    businessStats: [],
    conversationAnalytics: {}
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserStats.fulfilled, (state, action) => {
      state.userStats = action.payload;
    });
    builder.addCase(fetchBusinessStats.fulfilled, (state, action) => {
      state.businessStats = action.payload;
    });
    builder.addCase(fetchConversationAnalytics.fulfilled, (state, action) => {
      state.conversationAnalytics = action.payload;
    });
  },
});

export default statsSlice.reducer;
